var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { formatNumber } from "../../util/amountUtils";
import { DetailP } from "../common-styled-components";
import { theme } from "../../theme";
import { formatDate } from "../../util/timeUtils";
var TooltipContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  pointer-events: none;\n  background-color: white;\n  border: 1px ", "\n    ", ";\n  padding: 4px ", ";\n"], ["\n  position: absolute;\n  pointer-events: none;\n  background-color: white;\n  border: 1px ", "\n    ", ";\n  padding: 4px ", ";\n"])), function (p) { return (p.isPrediction ? "dashed" : "solid"); }, theme.colors.grey(3), theme.spacing(2));
var TooltipValue = styled(DetailP)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), theme.fontBold, theme.fontSize(-1));
var getPositionStyle = function (point, bbox, side, verticalSide) {
    var style = {};
    if (side === "left") {
        style.right = bbox.width - point.x;
    }
    else {
        style.left = point.x;
    }
    if (verticalSide === "bottom") {
        style.top = point.y;
    }
    else {
        style.bottom = bbox.height - point.y;
    }
    return style;
};
export var Tooltip = function (_a) {
    var _b = _a.tooltipData, point = _b.point, containerSize = _b.containerSize, data = _b.data, isPrediction = _b.isPrediction;
    var side = point.x >= containerSize.width / 2 ? "left" : "right";
    var verticalSide = containerSize.height > data.length * 25 + point.y ? "bottom" : "top";
    return (_jsx(TooltipContainer, __assign({ style: getPositionStyle(point, containerSize, side, verticalSide), isPrediction: isPrediction }, { children: data.map(function (series) { return (_jsx(TooltipValue, __assign({ style: { color: series.color } }, { children: series.dateValue !== undefined
                ? "".concat(formatDate(series.dateValue.date), ": ").concat(series.dateValue.value !== undefined
                    ? formatNumber(series.dateValue.value, 1)
                    : "N/A")
                : "" }), series.series)); }) })));
};
var templateObject_1, templateObject_2;
