var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useFetch from "../../../hooks/useFetch";
import { PageContent } from "../../common-styled-components";
import { log } from "../../../util/log";
import { LoaderStatus } from "../../../util/apiUtils";
import { theme } from "../../../theme";
import { PoolTable } from "../common/tables/PoolTable";
import { useMetadata } from "../../../hooks/useMetadata";
import { FleetTable } from "../common/tables/FleetTable";
import { ShipTable } from "../common/tables/ShipTable";
export default (function () {
    var pools = useFetch("pool/pools.json", JSON.stringify({ pool_id: null, year: 2024 }), {
        "Content-Type": "application/json",
    })[0];
    var fleets = useFetch("fleet/fleets.json", JSON.stringify({ year: 2024 }), {
        "Content-Type": "application/json",
    })[0];
    var ships = useFetch("ship/ships.json", JSON.stringify({ year: 2024 }), {
        "Content-Type": "application/json",
    })[0];
    var metadata = useMetadata()[0];
    log([
        {
            label: "Pools",
            content: pools.status === LoaderStatus.Success ? pools.data : "loading",
        },
        {
            label: "Fleets",
            content: fleets.status === LoaderStatus.Success ? fleets.data : "loading",
        },
        {
            label: "Ships",
            content: ships.status === LoaderStatus.Success ? ships.data : "loading",
        },
    ]);
    if (fleets.status !== LoaderStatus.Success ||
        !fleets.data ||
        pools.status !== LoaderStatus.Success ||
        !pools.data ||
        ships.status !== LoaderStatus.Success ||
        !ships.data ||
        !metadata) {
        return _jsx(PageContent, __assign({ align: "left" }, { children: "Loading\u2026" }));
    }
    return (_jsx("div", __assign({ style: { width: "100%", padding: theme.spacing(5) } }, { children: _jsxs(PageContent, __assign({ align: "left", style: { gap: theme.spacing(5) } }, { children: [_jsx(PoolTable, { pools: pools.data, isInAdminTools: false, metadata: metadata }), _jsx(FleetTable, { fleets: fleets.data, isInAdminTools: false, metadata: metadata }), _jsx(ShipTable, { ships: ships.data, isInAdminTools: false, metadata: metadata, complianceYear: 2025 })] })) })));
});
