var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
export var Auth0ProviderWithNavigate = function (_a) {
    var children = _a.children;
    var navigate = useNavigate();
    var onRedirectCallback = function (appState) {
        navigate((appState === null || appState === void 0 ? void 0 : appState.returnTo) || window.location.pathname);
    };
    return (_jsx(Auth0Provider, __assign({ domain: "ahticlimate.eu.auth0.com", clientId: "scPIVPmi02FXh3lJDSqdWZ2nY8JK9uTc", authorizationParams: {
            redirect_uri: window.location.origin,
            scope: "openid profile email roles offline_access",
            audience: "https://api.ahticlimate.com/v1/",
        }, onRedirectCallback: onRedirectCallback, cacheLocation: "localstorage", useRefreshTokens: true }, { children: children })));
};
