var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { PageContent } from "../../common-styled-components";
import useFetch from "../../../hooks/useFetch";
import { FleetTable } from "../common/tables/FleetTable";
import { useMetadata } from "../../../hooks/useMetadata";
import { LoaderStatus } from "../../../util/apiUtils";
export default (function () {
    var _a = useFetch("admin/fleets.json", JSON.stringify({
        year: 2024,
    }), {
        "Content-Type": "application/json",
    }), fleets = _a[0], _setUrl = _a[1], _setBody = _a[2], refresh = _a[3];
    var metadata = useMetadata()[0];
    if (fleets.status !== LoaderStatus.Success || !fleets.data || !metadata) {
        return _jsx(PageContent, __assign({ align: "left" }, { children: "Loading\u2026" }));
    }
    return (_jsx(PageContent, __assign({ align: "left" }, { children: _jsx(FleetTable, { fleets: fleets.data, metadata: metadata, isInAdminTools: true, refresh: refresh }) })));
});
