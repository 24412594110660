var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { chain, isNumber, some } from "lodash";
export var getInitialFossilFuelParam = function (metadata) {
    return getOriginFuelParam(1, 1, metadata);
};
export var getInitialSecondFossilFuelParam = function (metadata) {
    return getOriginFuelParam(2, 1, metadata);
};
export var getOriginFuelParam = function (fuel, engine, metadata) {
    return {
        fuel_type: fuel,
        engine_type: engine,
        fuel_price: metadata.fuels[fuel].price,
        foc: 100000,
        engine_efficiency: metadata["efficiency-fuel-engine"][fuel][engine],
    };
};
export var getInitialComparisonFuelParam = function (metadata) {
    return getComparisonFuelParam(23, 1, metadata);
};
export var getInitialSecondComparisonFuelParam = function (metadata) {
    return getComparisonFuelParam(21, 1, metadata);
};
export var getComparisonFuelParam = function (fuel, engine, metadata) {
    return {
        engine_type: engine,
        fuel_type: fuel,
        fuel_price: metadata.fuels[fuel].price,
        engine_change_cost: metadata.fuels[fuel].default_engine_change_cost,
        engine_efficiency: metadata["efficiency-fuel-engine"][fuel][engine],
        intensity: metadata["intensity-fuel-engine"][fuel][engine],
    };
};
export var MODEL_LAST_YEAR = 2050;
export var getYearMultipliers = function (allYears) {
    return allYears.reduce(function (result, y, i) {
        var _a;
        var years = 0;
        if (y === MODEL_LAST_YEAR) {
            years = 1;
        }
        else {
            years = allYears[i + 1] - y;
        }
        return __assign(__assign({}, result), (_a = {}, _a[y] = years, _a));
    }, {});
};
var calculateComparisonData = function (comparison, mixYears, yearMultipliers) {
    var mixPath = mixYears
        .map(function (year) {
        var mixYear = comparison.mixes.find(function (yearData) { return yearData.year === year; });
        if (!mixYear) {
            return undefined;
        }
        var fuel_cost = combineMixFuelItems("fuel_cost", mixYear);
        var emission_cost = combineMixFuelItems("emission_cost", mixYear);
        var annual_technology_cost_for_alternative_fuel_ships = mixYear.annual_technology_cost_for_alternative_fuel_ships;
        var co2_equivalent_emissions = combineMixFuelItems("co2_equivalent_emissions", mixYear);
        var penalty = mixYear.total_penalty_costs;
        return {
            year: year,
            fuel_cost: fuel_cost,
            emission_cost: emission_cost,
            annual_technology_cost_for_alternative_fuel_ships: annual_technology_cost_for_alternative_fuel_ships,
            co2_equivalent_emissions: co2_equivalent_emissions,
            penalty: penalty,
            value: fuel_cost +
                emission_cost +
                annual_technology_cost_for_alternative_fuel_ships +
                penalty,
        };
    })
        .filter(function (d) { return d; });
    var mixCumulativeCost = mixPath.reduce(function (r, v) { var _a; return r + ((_a = v === null || v === void 0 ? void 0 : v.value) !== null && _a !== void 0 ? _a : 0) * yearMultipliers[v === null || v === void 0 ? void 0 : v.year]; }, 0);
    var mixCumulativeEmissions = mixPath.reduce(function (r, v) { var _a; return r + ((_a = v === null || v === void 0 ? void 0 : v.co2_equivalent_emissions) !== null && _a !== void 0 ? _a : 0) * yearMultipliers[v === null || v === void 0 ? void 0 : v.year]; }, 0);
    var mixesHavePenalties = some(mixPath, function (y) { return y !== undefined && y.penalty > 0; });
    var compliance = mixPath.map(function (y) { return ({
        year: y.year,
        is_compliant: y.penalty === 0,
    }); });
    var fuelMixMaxCost = Math.max.apply(Math, mixPath.map(function (y) {
        var _a, _b, _c, _d;
        return Math.max((_a = y === null || y === void 0 ? void 0 : y.emission_cost) !== null && _a !== void 0 ? _a : 0, (_b = y === null || y === void 0 ? void 0 : y.penalty) !== null && _b !== void 0 ? _b : 0, (_c = y === null || y === void 0 ? void 0 : y.fuel_cost) !== null && _c !== void 0 ? _c : 0, (_d = y === null || y === void 0 ? void 0 : y.annual_technology_cost_for_alternative_fuel_ships) !== null && _d !== void 0 ? _d : 0);
    }));
    return {
        mixPath: mixPath,
        mixCumulativeCost: mixCumulativeCost,
        mixCumulativeEmissions: mixCumulativeEmissions,
        mixesHavePenalties: mixesHavePenalties,
        compliance: compliance,
        fuelMixMaxCost: fuelMixMaxCost,
    };
};
var combineMixFuelItems = function (key, mixYear) {
    var original = mixYear.original_fuel[key];
    var alternative = mixYear.alternative_fuel[key];
    if (isNumber(original) && isNumber(alternative)) {
        return original + alternative;
    }
    return 0;
};
export var getYearValues = function (mixYears, key) {
    return mixYears.map(function (mixYear) { return ({
        year: mixYear.year,
        value: mixYear[key],
    }); });
};
export var calculateResults = function (params, fuelTypes, calculation) {
    var originalFuel = calculation.original_fuel;
    var alternativeFuels = params.comparison_fuels.map(function (cf) { return fuelTypes[cf.fuel_type]; });
    var mixYears = chain(calculation.comparisons)
        .flatMap(function (c) { return c.mixes.map(function (year) { return year.year; }); })
        .uniq()
        .sort()
        .value();
    var lastYear = Math.max.apply(Math, mixYears);
    var lastAccumulationYear = lastYear === MODEL_LAST_YEAR ? MODEL_LAST_YEAR : lastYear + 4;
    var yearMultipliers = getYearMultipliers(mixYears);
    var originalPath = mixYears.map(function (year) {
        var _a, _b;
        var originalFuelYearDatum = originalFuel.timeline.find(function (p) { return p.year === year; });
        var penalty = (_a = originalFuelYearDatum === null || originalFuelYearDatum === void 0 ? void 0 : originalFuelYearDatum.penalty) !== null && _a !== void 0 ? _a : 0;
        var emission_cost = (_b = originalFuelYearDatum === null || originalFuelYearDatum === void 0 ? void 0 : originalFuelYearDatum.emission_cost) !== null && _b !== void 0 ? _b : 0;
        return {
            year: year,
            value: emission_cost + penalty + originalFuel.fuel_cost,
            emission_cost: emission_cost,
            penalty: penalty,
            co2_equivalent_emissions: calculation.co2_equivalent_emissions,
            fuel_cost: originalFuel.fuel_cost,
        };
    });
    var originalCumulativeCost = originalPath.reduce(function (r, v) { return r + v.value * yearMultipliers[v.year]; }, 0);
    var originalCumulativeEmissions = originalPath.reduce(function (r, v) { return r + v.co2_equivalent_emissions * yearMultipliers[v.year]; }, 0);
    var comparisonCalculations = calculation === null || calculation === void 0 ? void 0 : calculation.comparisons.map(function (comparison) {
        return calculateComparisonData(comparison, mixYears, yearMultipliers);
    });
    var originalMaxCost = Math.max.apply(Math, originalPath.map(function (y) {
        return Math.max(y.emission_cost, y.fuel_cost, y.penalty);
    }));
    var maxCost = Math.max.apply(Math, __spreadArray([originalMaxCost], comparisonCalculations.map(function (c) { return c.fuelMixMaxCost; }), false));
    var complianceData = {
        comparisons: comparisonCalculations.map(function (c) { return c.compliance; }),
        combined: mixYears.map(function (year) { return ({
            year: year,
            is_compliant: comparisonCalculations.every(function (calculation) { var _a; return (_a = calculation.compliance.find(function (cy) { return cy.year === year; })) === null || _a === void 0 ? void 0 : _a.is_compliant; }),
        }); }),
    };
    return {
        originalFuel: originalFuel,
        alternativeFuels: alternativeFuels,
        cumulativeCosts: {
            original: originalCumulativeCost,
            fuelMixes: comparisonCalculations.map(function (c) { return c.mixCumulativeCost; }),
        },
        cumulativeEmissions: {
            original: originalCumulativeEmissions,
            fuelMixes: comparisonCalculations.map(function (c) { return c.mixCumulativeEmissions; }),
        },
        originalPathTimeline: originalPath,
        fuelMixTimelines: comparisonCalculations.map(function (c) { return c.mixPath; }),
        lastAccumulationYear: lastAccumulationYear,
        complianceData: complianceData,
        maxCost: maxCost,
    };
};
export var calculateSurplusResults = function (calculation) {
    var originalFuel = calculation.original_fuel;
    var years = chain(calculation.co2_surplus)
        .flatMap(function (c) { return c.year; })
        .uniq()
        .sort()
        .value();
    var lastYear = Math.max.apply(Math, years);
    var lastAccumulationYear = lastYear === MODEL_LAST_YEAR ? MODEL_LAST_YEAR : lastYear + 4;
    var yearMultipliers = getYearMultipliers(years);
    var originalPath = years.map(function (year) {
        var _a, _b;
        var penalty = (_b = (_a = originalFuel.timeline.find(function (p) { return p.year === year; })) === null || _a === void 0 ? void 0 : _a.penalty) !== null && _b !== void 0 ? _b : 0;
        return {
            year: year,
            value: calculation.emission_cost + penalty + originalFuel.fuel_cost,
            emission_cost: calculation.emission_cost,
            penalty: penalty,
            co2_equivalent_emissions: calculation.co2_equivalent_emissions,
            fuel_cost: originalFuel.fuel_cost,
        };
    });
    var originalCumulativeCost = originalPath.reduce(function (r, v) { return r + v.value * yearMultipliers[v.year]; }, 0);
    var originalCumulativeEmissions = originalPath.reduce(function (r, v) { return r + v.co2_equivalent_emissions * yearMultipliers[v.year]; }, 0);
    var maxCost = Math.max.apply(Math, originalPath.map(function (y) {
        return Math.max(y.emission_cost, y.fuel_cost, y.penalty);
    }));
    var complianceData = {
        combined: calculation.penalty.map(function (yearValue) { return ({
            year: yearValue.year,
            is_compliant: yearValue.value === 0,
        }); }),
    };
    return {
        originalFuel: originalFuel,
        cumulativeCosts: {
            original: originalCumulativeCost,
        },
        cumulativeEmissions: {
            original: originalCumulativeEmissions,
        },
        originalPathTimeline: originalPath,
        lastAccumulationYear: lastAccumulationYear,
        complianceData: complianceData,
        maxCost: maxCost,
    };
};
export var canCreateCalculation = function (params, metadata) {
    var _a;
    var originalIntensity = metadata["intensity-fuel-engine"][params.fuels[0].fuel_type][params.fuels[0].engine_type];
    var compareIntensity = (_a = params.comparison_fuels[0].intensity) !== null && _a !== void 0 ? _a : metadata["intensity-fuel-engine"][params.comparison_fuels[0].fuel_type][params.comparison_fuels[0].engine_type];
    return originalIntensity > compareIntensity;
};
export var getPricePerGJ = function (pricePerTn, lcv) {
    // pricePerTon = €/tn
    // lcv = MJ/g
    var MJPerTn = lcv * 1000000;
    var GJPerTn = MJPerTn / 1000;
    var tnPerGJ = 1 / GJPerTn;
    var pricePerGJ = pricePerTn * tnPerGJ;
    return pricePerGJ;
};
