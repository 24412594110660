export var getUnixTimeStamp = function (date) {
    var time = date.getTime();
    var timeStamp = Math.round(time / 1000);
    return timeStamp;
};
var SECOND = 1000;
var MINUTE = SECOND * 60;
var HOUR = MINUTE * 60;
var DAY = HOUR * 24;
export var add24h = function (date) {
    var newTime = date.getTime() + DAY;
    return new Date(newTime);
};
export var sortDates = function (dateA, dateB) {
    return dateA.getTime() - dateB.getTime();
};
export var ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;
export var getDateFromDayOfYear = function (day, year) {
    var start = new Date(year, 0, 0).getTime();
    return new Date(start + day * ONE_DAY_IN_MS);
};
export var getDayOfYear = function (date) {
    var start = new Date(date.getFullYear(), 0, 0).getTime();
    return Math.floor((date.getTime() - start) / ONE_DAY_IN_MS);
};
var DATE_LOCALE = "en-US";
export var formatDate = function (date, showYear) {
    if (showYear) {
        return date.toLocaleString(DATE_LOCALE, {
            month: "short",
            day: "numeric",
            year: "numeric",
        });
    }
    return date.toLocaleDateString(DATE_LOCALE, {
        month: "short",
        day: "numeric",
    });
};
