/**
 * Converts client coordinates (mouse position) to SVG coordinates.
 *
 * @param {number} clientX - The x-coordinate of the mouse in the browser's viewport.
 * @param {number} clientY - The y-coordinate of the mouse in the browser's viewport.
 * @param {React.MutableRefObject<SVGSVGElement | null>} svgRef - A reference to the target SVG element.
 * @returns {DOMPoint | undefined} - The corresponding point in the SVG's coordinate system, or undefined if the SVG element is not available.
 */
export var getSVGPoint = function (clientX, clientY, svgRef) {
    var _a;
    if (svgRef.current !== null) {
        // Create an SVGPoint object for the given SVG element.
        var pt = svgRef.current.createSVGPoint();
        // Set the point's coordinates to the client coordinates.
        pt.x = clientX;
        pt.y = clientY;
        // Transform the point from screen space to the SVG's local coordinate space.
        var svgPoint = pt.matrixTransform((_a = svgRef.current.getScreenCTM()) === null || _a === void 0 ? void 0 : _a.inverse());
        return svgPoint;
    }
    // Return undefined if the SVG reference is not valid.
    return undefined;
};
