var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../../theme";
var adminTools = [
    {
        path: "/compliance/admin/pools",
        name: "Pools",
    },
    {
        path: "/compliance/admin/ships",
        name: "Ships",
    },
    {
        path: "/compliance/admin/transactions",
        name: "Transactions",
    },
    {
        path: "/compliance/admin/companies",
        name: "Companies",
    },
    {
        path: "/compliance/admin/fleets",
        name: "Fleets",
    },
    {
        path: "/compliance/admin/users",
        name: "Users",
    },
    {
        path: "/compliance/admin/test-data-tools",
        name: "Test data",
    },
];
var Navigation = styled.nav(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: ", ";\n  margin-bottom: ", ";\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: ", ";\n  margin-bottom: ", ";\n"])), theme.spacing(3), theme.spacing(5));
var StyledLink = styled(Link)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  text-transform: uppercase;\n  text-decoration: none;\n  ", ";\n\n  &:visited {\n    color: ", ";\n  }\n"], ["\n  color: ", ";\n  text-transform: uppercase;\n  text-decoration: none;\n  ", ";\n\n  &:visited {\n    color: ", ";\n  }\n"])), function (p) { return (p.$selected ? theme.colors.bluelink : theme.colors.grey(2)); }, theme.fontBold, function (p) {
    return p.$selected ? theme.colors.bluelink : theme.colors.grey(2);
});
export default (function () {
    var _a;
    var pathname = useLocation().pathname;
    var selectedTool = (_a = adminTools.find(function (tool) { return tool.path === pathname; })) === null || _a === void 0 ? void 0 : _a.name;
    return (_jsxs("div", __assign({ style: { width: "100%", padding: theme.spacing(5) } }, { children: [_jsx(Navigation, { children: adminTools.map(function (tool) { return (_jsx(StyledLink, __assign({ to: tool.path, "$selected": tool.name === selectedTool }, { children: tool.name }), tool.name)); }) }), _jsx(Outlet, {})] })));
});
var templateObject_1, templateObject_2;
