var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useDeepMemo } from "../../hooks/useDeepMemo";
import { theme } from "../../theme";
import { Bold, DetailP, GraphTitle, Grid, Legend, LegendItem, P, SectionTitle, TextContent, UISection, } from "./../common-styled-components";
import CompareBarChart, { getComparisonColor } from "../custom/CompareBarChart";
import TimelineChart from "../custom/TimelineChart";
import VoyageMap from "./VoyageMap";
import { MODEL_LAST_YEAR, calculateResults, getYearValues, } from "../../util/calculatorUtils";
import { formatNumber } from "../../util/amountUtils";
export default (function (_a) {
    var params = _a.params, fuelTypes = _a.fuelTypes, calculation = _a.calculation, metadata = _a.metadata, texts = _a.texts;
    var resultDetails = useDeepMemo(function () { return calculateResults(params, fuelTypes, calculation); }, [calculation]);
    var originalFuel = resultDetails.originalFuel, alternativeFuels = resultDetails.alternativeFuels, cumulativeCosts = resultDetails.cumulativeCosts, cumulativeEmissions = resultDetails.cumulativeEmissions, originalPathTimeline = resultDetails.originalPathTimeline, fuelMixTimelines = resultDetails.fuelMixTimelines, lastAccumulationYear = resultDetails.lastAccumulationYear, complianceData = resultDetails.complianceData, maxCost = resultDetails.maxCost;
    return (_jsx("div", { children: calculation && resultDetails && (_jsxs(_Fragment, { children: [_jsx(UISection, { children: _jsxs(TextContent, { children: [_jsx(SectionTitle, { children: "Voyage" }), _jsx(VoyageMap, { legs: calculation.legs }), _jsxs(DetailP, { children: ["From ", _jsx(Bold, { children: params.departure_port_label }), " to", " ", _jsx(Bold, { children: params.destination_port_label })] }), _jsxs(DetailP, { children: ["FOC: ", _jsx(Bold, { children: formatNumber(calculation.foc, 1) }), " tn"] }), _jsx(SectionTitle, __assign({ style: { marginTop: theme.spacing(4) } }, { children: "Calculation results" })), _jsx(GraphTitle, { children: "Total emissions" }), _jsx(Grid, { children: _jsx("div", { children: _jsx(CompareBarChart, { unit: "t", valueSeries: [
                                            {
                                                title: "Total emissions with fossil energy source",
                                                selectorLabel: "Total emissions with fossil energy source",
                                                values: [
                                                    {
                                                        name: "Total CO₂e emissions",
                                                        value: calculation.co2_equivalent_emissions,
                                                        color: theme.colors.grey(2),
                                                    },
                                                    {
                                                        name: "ETS applicable CO₂e emissions",
                                                        value: calculation.ets_co2_emissions,
                                                        color: theme.colors.green,
                                                    },
                                                ],
                                            },
                                        ], hideDifference: true }) }) }), _jsxs(Legend, { children: [_jsx(LegendItem, { color: theme.colors.grey(2), text: "Total CO\u2082e emissions" }), _jsx(LegendItem, { color: theme.colors.green, text: "ETS applicable CO\u2082e emissions" })] }), _jsx(TimelineChart, { title: "Fuel EU Maritime carbon intensity targets", subTitle: "The regulated carbon intensity target timeline, along with your energy sources' carbon intensities." // {texts.carbon_intensity_targets}
                                , unit: "gCO\u2082e/MJ", forceZero: true, forceDigits: 1, data: __spreadArray([
                                    {
                                        name: "".concat(originalFuel.fuel_name, " intensity"),
                                        color: theme.colors.grey(2),
                                        values: Object.keys(metadata.intensity).map(function (year) { return ({
                                            year: Number(year),
                                            value: calculation.carbon_intensity,
                                        }); }),
                                        hideCircles: true,
                                    },
                                    {
                                        name: texts.target_for_each_year,
                                        color: theme.colors.blue,
                                        values: Object.keys(metadata.intensity).map(function (year) { return ({
                                            year: Number(year),
                                            value: metadata.intensity[year],
                                        }); }),
                                    }
                                ], calculation.comparisons.map(function (c, i) { return ({
                                    name: "".concat(c.fuel_name, " intensity"),
                                    color: theme.colors.greenScale[i],
                                    values: Object.keys(metadata.intensity).map(function (year) { return ({
                                        year: Number(year),
                                        value: c.carbon_intensity,
                                    }); }),
                                    hideCircles: true,
                                }); }), true), chartType: "stepAfter" }), _jsx(TimelineChart, { title: "Emission penalties just using ".concat(originalFuel.fuel_name), subTitle: "Yearly penalties for this voyage using ".concat(originalFuel.fuel_name, " only, converted to $"), unit: "$", data: [
                                    {
                                        name: originalFuel.fuel_name,
                                        color: theme.colors.grey(2),
                                        values: calculation.penalty,
                                    },
                                ], canDivide: true, forceZero: true, chartType: "stepAfter" }), _jsx(SectionTitle, { children: "Energy mix plan for different years" }), _jsxs(P, { children: ["This is how the regulation affects the optimal fuel mix for the voyage for each five-year period starting 2025.", fuelMixTimelines.length > 1
                                        ? " A separate plan is made for each alternative energy source."
                                        : ""] }), calculation.comparisons.length &&
                                calculation.comparisons.map(function (comparison, index) {
                                    var comparisonFuel = alternativeFuels[index];
                                    return comparisonFuel && comparison.mixes.length ? (_jsxs("div", { children: [_jsxs(GraphTitle, { children: ["Energy source mix with ", comparisonFuel.description] }), _jsx(TimelineChart, { subTitle: "How much of each fuel should be used to meet the carbon intensity targets", unit: texts.weight_unit, forceZero: true, canDivide: true, errorYearMessage: texts.no_compliance, complianceData: complianceData.combined, data: [
                                                    {
                                                        name: "FOC, ".concat(originalFuel.fuel_name),
                                                        color: theme.colors.grey(2),
                                                        values: comparison.mixes.map(function (mixYear) {
                                                            return {
                                                                year: mixYear.year,
                                                                value: mixYear.original_fuel.foc,
                                                            };
                                                        }),
                                                    },
                                                    {
                                                        name: "FOC, ".concat(comparisonFuel.description),
                                                        color: theme.colors.green,
                                                        values: comparison.mixes.map(function (m) {
                                                            return {
                                                                year: m.year,
                                                                value: m.alternative_fuel.foc,
                                                            };
                                                        }),
                                                    },
                                                ], chartType: "stepAfter" }), _jsx(TimelineChart, { subTitle: texts.energy_source_mix_percentage, unit: "%", forceZero: true, errorYearMessage: texts.no_compliance, complianceData: complianceData.combined, data: [
                                                    {
                                                        name: "Weight percentage of ".concat(originalFuel.fuel_name),
                                                        color: theme.colors.grey(2),
                                                        values: comparison.mixes.map(function (m) {
                                                            return {
                                                                year: m.year,
                                                                value: m.original_fuel.weight_percentage,
                                                            };
                                                        }),
                                                    },
                                                    {
                                                        name: "Weight percentage of ".concat(comparisonFuel.description),
                                                        color: theme.colors.green,
                                                        values: comparison.mixes.map(function (m) {
                                                            return {
                                                                year: m.year,
                                                                value: m.alternative_fuel.weight_percentage,
                                                            };
                                                        }),
                                                    },
                                                ], chartType: "stepAfter" })] }, comparisonFuel.fuel)) : (_jsx(P, { children: texts.no_fuel_mix }));
                                })] }) }), _jsx(UISection, { children: _jsxs(TextContent, { children: [_jsx(SectionTitle, { children: "Fossil path vs. fuel mix path" }), _jsxs(P, { children: ["These graphs compare the total cost and emissions of using the original fossil fuel versus mixing to the new fuel according to our fuel mix plan, from the year 2025 to ", lastAccumulationYear, ", taking into account emission costs and penalties.", lastAccumulationYear !== MODEL_LAST_YEAR
                                        ? " Note that the current plan is not compliant into the year ".concat(MODEL_LAST_YEAR)
                                        : ""] }), _jsxs(Grid, { children: [_jsx("div", { children: cumulativeEmissions.fuelMixes &&
                                            cumulativeEmissions.fuelMixes.length > 0 && (_jsx(CompareBarChart, { unit: texts.weight_unit, valueSeries: [
                                                {
                                                    title: "Total emissions 2025-".concat(lastAccumulationYear),
                                                    selectorLabel: "Total emissions 2025-".concat(lastAccumulationYear),
                                                    values: [
                                                        {
                                                            name: "Original",
                                                            value: cumulativeEmissions.original,
                                                            color: getComparisonColor(0),
                                                        },
                                                    ].concat(cumulativeEmissions.fuelMixes.map(function (fm, i) { return ({
                                                        name: "Fuel mix with ".concat(alternativeFuels[i].description),
                                                        value: fm,
                                                        color: getComparisonColor(i + 1),
                                                    }); })),
                                                },
                                            ] })) }), _jsx("div", { children: cumulativeCosts.fuelMixes &&
                                            cumulativeEmissions.fuelMixes.length > 0 && (_jsx(CompareBarChart, { unit: "M$", valueSeries: [
                                                {
                                                    title: "Total cost 2025-".concat(lastAccumulationYear),
                                                    selectorLabel: "Total cost 2025-".concat(lastAccumulationYear),
                                                    values: [
                                                        {
                                                            name: "Original",
                                                            value: cumulativeCosts.original / 1000000,
                                                            color: getComparisonColor(0),
                                                        },
                                                    ].concat(cumulativeCosts.fuelMixes.map(function (fm, i) { return ({
                                                        name: alternativeFuels[i].description,
                                                        value: fm / 1000000,
                                                        color: getComparisonColor(i + 1),
                                                    }); })),
                                                },
                                            ] })) })] }), _jsxs(Legend, { children: [_jsx(LegendItem, { color: getComparisonColor(0), text: originalFuel.fuel_name }), alternativeFuels.map(function (f, i) { return (_jsx(LegendItem, { color: getComparisonColor(i + 1), text: "Fuel mix plan towards " + f.description }, f.description)); })] }), (cumulativeCosts === null || cumulativeCosts === void 0 ? void 0 : cumulativeCosts.fuelMixes.length) > 0 &&
                                fuelMixTimelines.filter(function (fuelMix) {
                                    return fuelMix.every(function (y) { return y !== undefined; });
                                }).length > 0 && (_jsxs(_Fragment, { children: [_jsx(TimelineChart, { title: "Yearly cost of fossil path versus fuel mix path", subTitle: "Comparison of fossil path costs (fuel cost, emission cost and emission penalties) to fuel mix costs (engine change costs, cost of fuels, and emission costs). Engine change cost represents the CAPEX needed to enable the use of the selected alternative fuel. It is a rough premium of a ship with a possibility to use the selected alternative fuel compared to a similar size, conventional fuel vessel. The cost is allocated over the vessel's life-cycle period of 20 years.", unit: "$", canDivide: true, forceZero: true, errorYearMessage: texts.no_compliance, complianceData: complianceData.combined, data: [
                                            {
                                                name: "Cost of plan with ".concat(originalFuel.fuel_name, " only"),
                                                color: theme.colors.grey(2),
                                                values: originalPathTimeline,
                                            },
                                        ].concat(fuelMixTimelines.map(function (fm, i) {
                                            var _a;
                                            return ({
                                                name: "Yearly cost of migrating from ".concat(originalFuel.fuel_name, " to ").concat(alternativeFuels[i].description),
                                                color: (_a = theme.colors.greenScale[i]) !== null && _a !== void 0 ? _a : "black",
                                                values: fm.filter(function (v) { return v; }),
                                            });
                                        })), chartType: "stepAfter" }), _jsx(TimelineChart, { subTitle: "Cost breakdown of original path (".concat(originalFuel.fuel_name, ")"), unit: "$", canDivide: true, forceZero: true, forcedMaxValue: maxCost, errorYearMessage: texts.no_compliance, complianceData: complianceData.combined, data: [
                                            {
                                                name: "Fuel cost",
                                                color: theme.colors.grey(2),
                                                values: originalPathTimeline.map(function (v) { return ({
                                                    year: v.year,
                                                    value: v.fuel_cost,
                                                }); }),
                                            },
                                            {
                                                name: "Emission cost",
                                                color: theme.colors.grey(1),
                                                values: originalPathTimeline.map(function (v) { return ({
                                                    year: v.year,
                                                    value: v.emission_cost,
                                                }); }),
                                            },
                                            {
                                                name: "Penalties",
                                                color: theme.colors.grey(0),
                                                values: originalPathTimeline.map(function (v) { return ({
                                                    year: v.year,
                                                    value: v.penalty,
                                                }); }),
                                            },
                                        ], chartType: "bar" }), fuelMixTimelines.map(function (fm, i) { return (_jsx(TimelineChart, { subTitle: "Cost breakdown of fuel mix plan towards ".concat(alternativeFuels[i].description), unit: "$", canDivide: true, forceZero: true, forcedMaxValue: maxCost, errorYearMessage: texts.no_compliance, complianceData: complianceData.comparisons[i], data: [
                                            {
                                                name: "Fuel cost",
                                                color: theme.colors.lightGreen,
                                                values: fm
                                                    .filter(function (v) { return v; })
                                                    .map(function (v) { return ({
                                                    year: v.year,
                                                    value: v.fuel_cost,
                                                }); }),
                                            },
                                            {
                                                name: "Emission cost",
                                                color: theme.colors.green,
                                                values: fm
                                                    .filter(function (v) { return v; })
                                                    .map(function (v) { return ({
                                                    year: v.year,
                                                    value: v.emission_cost,
                                                }); }),
                                            },
                                            {
                                                name: "Penalties",
                                                color: theme.colors.darkGreen,
                                                values: fm
                                                    .filter(function (v) { return v; })
                                                    .map(function (v) { return ({
                                                    year: v.year,
                                                    value: v.penalty,
                                                }); }),
                                            },
                                            {
                                                name: "Engine change cost",
                                                color: theme.colors.darkestGreen,
                                                values: fm
                                                    .filter(function (v) { return v; })
                                                    .map(function (v) { return ({
                                                    year: v.year,
                                                    value: v
                                                        .annual_technology_cost_for_alternative_fuel_ships,
                                                }); }),
                                            },
                                        ], chartType: "bar" }, alternativeFuels[i].description)); })] }))] }) }), _jsx(UISection, { children: _jsxs(TextContent, { children: [calculation.comparisons.map(function (comparisonData, i) { return (_jsx(TimelineChart, { title: texts.emissions_and_allowance_title, subTitle: "The fuel mix plan is designed to follow the emission allowance limit.", unit: "tCO\u2082e/y", forceZero: true, canDivide: true, errorYearMessage: texts.no_compliance, complianceData: complianceData.comparisons[i], data: [
                                    {
                                        name: texts.emissions_and_allowance_item,
                                        color: theme.colors.blue,
                                        values: comparisonData.allowed_co2_emissions,
                                    },
                                    {
                                        name: originalFuel.fuel_name,
                                        color: theme.colors.grey(2),
                                        values: comparisonData.allowed_co2_emissions.map(function (d) { return (__assign(__assign({}, d), { value: calculation.co2_equivalent_emissions })); }),
                                    },
                                    {
                                        name: "Fuel mix plan with ".concat(alternativeFuels[i].description),
                                        color: theme.colors.green,
                                        values: getYearValues(comparisonData.mixes, "fuel_mix_co2_equivalent_emissions"),
                                    },
                                ], chartType: "stepAfter" }, alternativeFuels[i].fuel)); }), _jsx(TimelineChart, { title: "Annual emission reduction", unit: "tCO\u2082e/y", canDivide: true, errorYearMessage: texts.no_compliance, complianceData: complianceData.combined, data: calculation.comparisons.map(function (comparisonData, i) { return ({
                                    name: "Fuel mix plan with ".concat(alternativeFuels[i].description),
                                    color: theme.colors.greenScale[i],
                                    values: getYearValues(comparisonData.mixes, "co2e_reduction"),
                                }); }), chartType: "bar" }), _jsx(TimelineChart, { title: "Cost per reduced CO\u2082e ton", subTitle: "Calculated based on the emission reduction obtained with the alternative fuel, compared to the original fuel and divided by the cost difference of using the alternative fuel.", unit: "$", canDivide: true, errorYearMessage: texts.no_compliance, complianceData: complianceData.combined, data: calculation.comparisons.map(function (comparisonData, i) { return ({
                                    name: "Cost per reduced CO\u2082e ton, ".concat(alternativeFuels[i].description),
                                    color: theme.colors.greenScale[i],
                                    values: comparisonData.cost_per_reduced_emission_ton.find(function (c) {
                                        return !c.alternative &&
                                            !c.with_emissions &&
                                            !c.with_engine &&
                                            !c.with_penalty;
                                    }).timeline,
                                }); }), chartType: "stepAfter" })] }) })] })) }));
});
