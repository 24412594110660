var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PageContent, SectionTitle } from "../../common-styled-components";
import useFetch from "../../../hooks/useFetch";
import { LoaderStatus } from "../../../util/apiUtils";
import { log } from "../../../util/log";
import { TransactionTable } from "../common/tables/TransactionTable";
export default (function () {
    var _a = useFetch("admin/transactions.json", JSON.stringify({
        year: 2024,
    }), {
        "Content-Type": "application/json",
    }), transactions = _a[0], _setUrl = _a[1], _setBody = _a[2], refresh = _a[3];
    if (transactions.status !== LoaderStatus.Success) {
        return _jsx(PageContent, { children: "Loading\u2026" });
    }
    log([
        {
            label: "Transactions",
            content: transactions.data,
        },
    ]);
    return (_jsxs(PageContent, __assign({ align: "left" }, { children: [_jsx(SectionTitle, { children: "Compliance transactions" }), _jsx(TransactionTable, { transactions: transactions.data, complianceYear: 0, refresh: refresh, isInAdminTools: true })] })));
});
