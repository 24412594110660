var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import styled from "styled-components";
import { theme } from "../../theme";
var InfoIcon = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: help;\n  border: 1px solid ", ";\n  border-radius: 50%;\n  width: 16px;\n  height: 16px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding-bottom: 3px;\n  font-size: 12px;\n  color: ", ";\n"], ["\n  cursor: help;\n  border: 1px solid ", ";\n  border-radius: 50%;\n  width: 16px;\n  height: 16px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding-bottom: 3px;\n  font-size: 12px;\n  color: ", ";\n"])), function (p) { return p.color; }, function (p) { return p.color; });
var POPUP_WIDTH = 200;
var InfoPopup = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  top: 50%;\n  ", "\n  width: ", "px;\n  max-width: ", "px;\n  padding: ", ";\n  background-color: ", ";\n  border: 1px solid ", ";\n  border-radius: 4px;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);\n  z-index: 100;\n  ", ";\n  pointer-events: none;\n  line-height: 1.5;\n"], ["\n  position: absolute;\n  top: 50%;\n  ", "\n  width: ", "px;\n  max-width: ", "px;\n  padding: ", ";\n  background-color: ", ";\n  border: 1px solid ", ";\n  border-radius: 4px;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);\n  z-index: 100;\n  ", ";\n  pointer-events: none;\n  line-height: 1.5;\n"])), function (p) {
    return p.side === "left"
        ? "\n    right: 100%;\n    transform: translate(-8px, -50%);\n    "
        : "\n    left: 100%;\n    transform: translate(8px, -50%);\n    ";
}, POPUP_WIDTH, POPUP_WIDTH, theme.spacing(2), theme.colors.white, theme.colors.grey(4), theme.fontSize(-1));
var InfoButton = function (_a) {
    var children = _a.children, _b = _a.color, color = _b === void 0 ? theme.colors.grey(1) : _b;
    var _c = useState(undefined), hoverSide = _c[0], setHoverSide = _c[1];
    var onHover = function (e) {
        var xSpaceRight = window.innerWidth - e.clientX;
        if (xSpaceRight > POPUP_WIDTH + 20) {
            setHoverSide("right");
        }
        else {
            setHoverSide("left");
        }
    };
    return (_jsxs("div", __assign({ style: { position: "relative", display: "inline-block" }, onMouseEnter: onHover, onMouseLeave: function () { return setHoverSide(undefined); } }, { children: [_jsx(InfoIcon, __assign({ color: color }, { children: "\u2139" })), hoverSide && _jsx(InfoPopup, __assign({ side: hoverSide }, { children: children }))] })));
};
export default InfoButton;
var templateObject_1, templateObject_2;
