var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { scaleLinear, scaleTime } from "d3-scale";
import { curveLinear, line } from "d3-shape";
import { withParentDimensions, } from "../generic/withParentDimensions";
import { useDeepMemo } from "../../hooks/useDeepMemo";
import { chain, flatMap, maxBy, sortBy } from "lodash";
import { GraphTitle, Legend, LegendItem } from "../common-styled-components";
import styled from "styled-components";
import { theme } from "../../theme";
import { formatNumber, getDivider, getDividerLabel, } from "../../util/amountUtils";
import { getClosestDateValue, getLatestDateValue, } from "../../util/statisticsUtils";
import { useRef, useState } from "react";
import { getSVGPoint } from "../generic/getSVGPoint";
import { Tooltip } from "../generic/Tooltip";
import { formatDate } from "../../util/timeUtils";
var LimitLine = styled.line(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  stroke-dasharray: 5 2;\n  stroke-width: 1px;\n  fill: none;\n"], ["\n  stroke-dasharray: 5 2;\n  stroke-width: 1px;\n  fill: none;\n"])));
export default withParentDimensions(function (_a) {
    var data = _a.data, limits = _a.limits, title = _a.title, unit = _a.unit, poiData = _a.poiData, width = _a.parentDimensions.width;
    var HEIGHT = 200;
    var MARGINS = {
        top: 18,
        bottom: 20,
        left: 80,
        right: 25,
    };
    var CONTENT_BOTTOM = HEIGHT - MARGINS.bottom;
    var TICK_FONT_SIZE = 12;
    var svgRef = useRef(null);
    var _b = useState(undefined), tooltipData = _b[0], setTooltipData = _b[1];
    var _c = useDeepMemo(function () {
        var _a, _b;
        var allDateValues = flatMap(data, function (d) { return d.data; }).concat(data.map(function (d) { return d.prediction; }).filter(function (d) { return d; }));
        var timeValues = allDateValues.map(function (d) { return d.date.getTime(); });
        var minTime = Math.min.apply(Math, timeValues);
        var maxTime = Math.max.apply(Math, timeValues);
        var scaleX = scaleTime()
            .range([MARGINS.left, width - MARGINS.right])
            .domain([new Date(minTime), new Date(maxTime)]);
        var values = allDateValues
            .map(function (d) { return d.value; })
            .filter(function (d) { return d !== undefined && !isNaN(d); });
        var limitValues = (_a = limits === null || limits === void 0 ? void 0 : limits.map(function (l) { return l.value; })) !== null && _a !== void 0 ? _a : [];
        var allValues = values.concat(limitValues);
        var minY = Math.min.apply(Math, allValues);
        var maxY = Math.max.apply(Math, allValues);
        var divider = (_b = getDivider(maxY)) !== null && _b !== void 0 ? _b : 1;
        var scaleY = scaleLinear()
            .range([CONTENT_BOTTOM, MARGINS.top])
            .domain([minY, maxY])
            .nice();
        var lineGenerator = line()
            .x(function (d) { return scaleX(d.date); })
            .defined(function (d) { return d !== undefined && !isNaN(d.value); })
            .y(function (d) { return (d.value !== undefined ? scaleY(d.value) : 0); })
            .curve(curveLinear);
        var sortedData = data.map(function (series) { return (__assign(__assign({}, series), { data: sortBy(series.data, function (d) { return new Date(d.date); }) })); });
        var poisAndValues = poiData === null || poiData === void 0 ? void 0 : poiData.map(function (poi) {
            var _a, _b;
            return (__assign(__assign({}, poi), { value: (_a = poi.value) !== null && _a !== void 0 ? _a : (_b = getClosestDateValue(sortedData[0].data, poi.date)) === null || _b === void 0 ? void 0 : _b.value }));
        });
        return [
            scaleX,
            scaleY,
            lineGenerator,
            divider,
            sortedData,
            poisAndValues,
        ];
    }, [data, poiData, limits, width]), scaleX = _c[0], scaleY = _c[1], lineGenerator = _c[2], divider = _c[3], sortedData = _c[4], poisWithValues = _c[5];
    var zeroLine = scaleY(0);
    var zeroLineInBounds = zeroLine >= MARGINS.top && zeroLine <= HEIGHT - MARGINS.bottom;
    var mouseMoved = function (e) {
        var _a, _b;
        e.preventDefault();
        var svgPoint = getSVGPoint(e.clientX, e.clientY, svgRef);
        if (svgPoint) {
            var date_1 = scaleX.invert(svgPoint.x);
            var latestData = sortedData.map(function (series) {
                return {
                    series: series.name,
                    color: series.color,
                    dateValue: getLatestDateValue(series.data, date_1),
                };
            });
            var latestDate = (_b = (_a = maxBy(latestData, function (d) { var _a; return (_a = d.dateValue) === null || _a === void 0 ? void 0 : _a.date.getTime(); })) === null || _a === void 0 ? void 0 : _a.dateValue) === null || _b === void 0 ? void 0 : _b.date;
            if (!latestDate) {
                return;
            }
            var predictionDate = chain(sortedData)
                .map(function (s) { var _a; return (_a = s.prediction) === null || _a === void 0 ? void 0 : _a.date; })
                .filter(function (d) { return d !== undefined; })
                .uniqBy(function (d) { return d.getTime(); })
                .valueOf();
            var tooltipData_1 = {
                point: svgPoint,
                containerSize: {
                    width: width,
                    height: HEIGHT,
                },
                data: [],
            };
            if ((predictionDate === null || predictionDate === void 0 ? void 0 : predictionDate.length) &&
                Math.abs(predictionDate[0].getTime() - date_1.getTime()) <
                    Math.abs(latestDate.getTime() - date_1.getTime())) {
                tooltipData_1.data = sortedData.map(function (series) { return ({
                    color: series.color,
                    series: series.name,
                    dateValue: series.prediction,
                }); });
                tooltipData_1.isPrediction = true;
            }
            else {
                tooltipData_1.data = latestData;
            }
            setTooltipData(tooltipData_1);
        }
    };
    var mouseOut = function (e) {
        e.preventDefault();
        setTooltipData(undefined);
    };
    return (_jsxs("div", { children: [_jsx(GraphTitle, { children: title }), _jsxs("div", __assign({ style: { position: "relative" } }, { children: [_jsxs("svg", __assign({ width: width, height: HEIGHT, onMouseMove: mouseMoved, onMouseOut: mouseOut, ref: svgRef }, { children: [scaleX.ticks(Math.round(width / 120)).map(function (t) {
                                var tickX = scaleX(t);
                                var tickLength = 6;
                                return (_jsxs("g", { children: [_jsx("line", { x1: tickX, x2: tickX, y1: CONTENT_BOTTOM, y2: CONTENT_BOTTOM + tickLength, strokeWidth: 1, stroke: "#aaa" }), _jsx("text", __assign({ x: tickX, textAnchor: "middle", y: CONTENT_BOTTOM + tickLength + TICK_FONT_SIZE, fontSize: TICK_FONT_SIZE }, { children: formatDate(t) }))] }, t.toString()));
                            }), scaleY.ticks(4).map(function (t) {
                                var tickY = scaleY(t);
                                var tickLength = 6;
                                return (_jsxs("g", { children: [_jsx("line", { x1: width - MARGINS.right, x2: MARGINS.left - tickLength, y1: tickY, y2: tickY, strokeWidth: 1, stroke: theme.colors.grey(4), className: "stroke-gray-200" }), _jsxs("text", __assign({ x: MARGINS.left - tickLength, textAnchor: "end", y: tickY + TICK_FONT_SIZE / 2 - 2, fontSize: TICK_FONT_SIZE }, { children: [formatNumber(t / divider), getDividerLabel(divider)] }))] }, t.toString()));
                            }), zeroLineInBounds && (_jsx("line", { strokeWidth: 1, stroke: "#aaa", x1: MARGINS.left, x2: width - MARGINS.right, y1: zeroLine, y2: zeroLine })), _jsx("line", { strokeWidth: 1, stroke: "#aaa", x1: MARGINS.left, x2: MARGINS.left, y1: CONTENT_BOTTOM, y2: 0 }), _jsx("text", __assign({ x: MARGINS.left + 4, y: MARGINS.top - 3, fontSize: TICK_FONT_SIZE }, { children: unit })), limits === null || limits === void 0 ? void 0 : limits.map(function (limit) { return (_jsx(LimitLine, { x1: MARGINS.left, x2: width - MARGINS.right, y1: scaleY(limit.value), y2: scaleY(limit.value), stroke: limit.color }, limit.label)); }), sortedData.map(function (series) {
                                var _a;
                                var lastInSeries = series.data[series.data.length - 1];
                                return (_jsxs("g", { children: [_jsx("path", { d: (_a = lineGenerator(series.data)) !== null && _a !== void 0 ? _a : "", fill: "none", stroke: series.color, strokeWidth: 1.5, strokeDasharray: series.dashed ? "3 2" : undefined, strokeLinejoin: "round", strokeLinecap: "round" }), series.prediction &&
                                            series.data.length > 0 &&
                                            lastInSeries.value !== undefined && (_jsx("line", { fill: "none", stroke: series.color, strokeDasharray: "2 2", strokeWidth: 1.5, opacity: 0.7, x1: scaleX(lastInSeries.date), x2: scaleX(series.prediction.date), y1: scaleY(lastInSeries.value), y2: scaleY(series.prediction.value) }))] }, series.name));
                            }), poisWithValues === null || poisWithValues === void 0 ? void 0 : poisWithValues.map(function (poi) { return (_jsx("circle", { cx: scaleX(poi.date), cy: poi.value !== undefined
                                    ? scaleY(poi.value)
                                    : HEIGHT - MARGINS.bottom, r: 3, fill: poi.color }, poi.date.toString() + poi.text)); }), tooltipData &&
                                tooltipData.data.map(function (d) {
                                    return d.dateValue && d.dateValue.value !== undefined ? (_jsx("circle", { cx: scaleX(d.dateValue.date), cy: scaleY(d.dateValue.value), fill: d.color, r: 2 }, d.series)) : undefined;
                                })] })), tooltipData && _jsx(Tooltip, { tooltipData: tooltipData })] })), _jsxs(Legend, { children: [data.map(function (series) { return (_jsx(LegendItem, { color: series.color, text: series.name }, series.name)); }), limits === null || limits === void 0 ? void 0 : limits.map(function (limit) { return (_jsx(LegendItem, { color: limit.color, text: limit.label }, limit.label)); })] })] }));
});
var templateObject_1;
