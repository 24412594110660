var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { routeNames } from "../../../../util/routes";
import { Button, ComplianceIcon, SmallButton, Table, TD, TH, TitleWithExtraContent, TR, } from "../../../common-styled-components";
import { useState } from "react";
import { Modal } from "../../common/Modal";
import useEdit from "../../../../hooks/useEdit";
import { Link } from "react-router-dom";
import { formatNumber } from "../../../../util/amountUtils";
import { complianceOptions, getCompliance, } from "../../../../util/compliance/compliance";
var tableColumns = function (isInAdminTools) {
    return [
        {
            key: "name",
            label: "Name",
        },
        {
            key: "ships",
            label: "Ships",
        },
        {
            key: "intensity",
            label: "Intensity",
        },
        {
            key: "balance",
            label: "Balance",
        },
        {
            key: "tools",
            label: "Tools",
        },
    ].filter(function (t) { return t.key !== "tools" || isInAdminTools; });
};
var CellContent = function (_a) {
    var isInAdminTools = _a.isInAdminTools, pool = _a.pool, contentKey = _a.contentKey, editPool = _a.editPool, compliance = _a.compliance, removePool = _a.removePool;
    switch (contentKey) {
        case "name":
            var link = isInAdminTools
                ? "".concat(routeNames.admin, "/").concat(routeNames.poolAdmin, "/").concat(pool.id)
                : "".concat(routeNames.pool, "/").concat(pool.id);
            return _jsx(Link, __assign({ to: link }, { children: pool.name }));
        case "ships":
            return _jsx("div", { children: pool.ships.length });
        case "balance":
            if (pool.intensity) {
                return (_jsxs("div", __assign({ style: { color: complianceOptions[compliance].color } }, { children: [pool.intensity.adjusted_emission_balance_ton > 0 ? "+" : "", formatNumber(pool.intensity.adjusted_emission_balance_ton, 0)] })));
            }
            else {
                return _jsx("div", {});
            }
        case "tools":
            return removePool && editPool ? (_jsxs("div", { children: [_jsx(SmallButton, __assign({ onClick: function () { return removePool(pool); } }, { children: "Remove" })), _jsx(SmallButton, __assign({ onClick: function () { return editPool(pool); } }, { children: "Edit" }))] })) : (_jsx("div", {}));
        case "intensity":
            if (pool.intensity) {
                return (_jsxs("div", __assign({ style: { color: complianceOptions[compliance].color } }, { children: [_jsx(ComplianceIcon, { src: complianceOptions[compliance].icon }), formatNumber(pool.intensity.adjusted_intensity_gCO2e_per_MJ, 2)] })));
            }
            else {
                return _jsx("div", {});
            }
        default:
            return _jsx("div", { children: pool[contentKey] });
    }
};
export var PoolTable = function (_a) {
    var pools = _a.pools, isInAdminTools = _a.isInAdminTools, refresh = _a.refresh, metadata = _a.metadata;
    var _b = useEdit("admin/pool.json", {
        "Content-Type": "application/json",
    }, refresh), editStatus = _b[0], executeEdit = _b[1];
    var _c = useState({}), modal = _c[0], setModal = _c[1];
    var addPool = function () {
        setModal({
            type: "add",
            value: undefined,
        });
    };
    var editPool = function (pool) {
        setModal({
            type: "edit",
            value: pool,
        });
    };
    var removePool = function (pool) {
        setModal({
            type: "remove",
            value: pool,
        });
    };
    var submit = function (type, value) {
        var _a;
        setModal({});
        if (type === "add" && isInAdminTools) {
            if (value.name && value.name !== "") {
                var data = {
                    name: value.name,
                    id: null,
                };
                executeEdit(data, "PUT");
            }
            else {
                alert("Empty name");
            }
        }
        if (type === "edit" && isInAdminTools) {
            if (value.name && value.name !== "") {
                var data = {
                    name: value.name,
                    id: (_a = value.id) !== null && _a !== void 0 ? _a : null,
                };
                executeEdit(data, "PUT");
            }
            else {
                alert("Empty name");
            }
        }
        if (type === "remove" && isInAdminTools) {
            if (value.id !== undefined) {
                var data = {
                    id: value.id,
                };
                executeEdit(data, "DELETE");
            }
        }
    };
    return (_jsxs("div", { children: [isInAdminTools && (_jsx(Modal, { type: "pool", isOpen: modal.type !== undefined, closeModal: function () { return setModal({}); }, modalType: modal.type, initialValue: modal.value, submit: submit })), _jsx(TitleWithExtraContent, __assign({ titleLevel: "Section", extraContent: isInAdminTools && _jsx(Button, __assign({ onClick: addPool }, { children: "+ Add" })) }, { children: "Pools" })), _jsxs(Table, { children: [_jsx("thead", { children: _jsx("tr", { children: tableColumns(isInAdminTools).map(function (c) { return (_jsx(TH, { children: c.label }, c.key)); }) }) }), _jsx("tbody", { children: pools.map(function (pool) {
                            var compliance = getCompliance(metadata, 2025, pool.intensity.adjusted_intensity_gCO2e_per_MJ);
                            return (_jsx(TR, { children: tableColumns(isInAdminTools).map(function (c) { return (_jsx(TD, { children: _jsx(CellContent, { isInAdminTools: isInAdminTools, contentKey: c.key, pool: pool, removePool: isInAdminTools ? removePool : undefined, editPool: isInAdminTools ? editPool : undefined, compliance: compliance }) }, c.key)); }) }, pool.id));
                        }) })] })] }));
};
