var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useState, useEffect, useReducer } from "react";
import { LoaderStatus, getAPIUrl } from "../util/apiUtils";
import { getAccessToken, useAuthentication } from "../util/auth";
var fetchData = function (setResponse, fetchUrl, signal, getAuthHeader, getAccessTokenSilently, body, headers) { return __awaiter(void 0, void 0, void 0, function () {
    var accessToken, authHeader, headersWithAuth, result, data, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!fetchUrl) return [3 /*break*/, 7];
                setResponse({ status: LoaderStatus.Loading });
                _a.label = 1;
            case 1:
                _a.trys.push([1, 6, , 7]);
                return [4 /*yield*/, getAccessToken(getAccessTokenSilently)];
            case 2:
                accessToken = _a.sent();
                authHeader = getAuthHeader(accessToken);
                headersWithAuth = __assign(__assign({}, headers), authHeader);
                return [4 /*yield*/, fetch(getAPIUrl() + fetchUrl, {
                        signal: signal,
                        method: body ? "POST" : "GET",
                        body: body,
                        headers: headersWithAuth,
                    })];
            case 3:
                result = _a.sent();
                if (!!signal.aborted) return [3 /*break*/, 5];
                return [4 /*yield*/, result.json()];
            case 4:
                data = _a.sent();
                if (data.error) {
                    setResponse({ status: LoaderStatus.Error, error: data.error });
                }
                else {
                    setResponse({ status: LoaderStatus.Success, data: data });
                }
                _a.label = 5;
            case 5: return [3 /*break*/, 7];
            case 6:
                error_1 = _a.sent();
                if (signal.aborted) {
                    console.log("Fetch aborted:", error_1);
                }
                else {
                    console.error(error_1);
                    setResponse({ status: LoaderStatus.Error, error: error_1 });
                }
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var useFetch = function (initialUrl, initialBody, headers, waitForBody) {
    var _a = useAuthentication(), getAuthHeader = _a.getAuthHeader, getAccessTokenSilently = _a.getAccessTokenSilently;
    var reducer = function (oldsState, newState) {
        if (newState.status === LoaderStatus.Idle) {
            return { status: LoaderStatus.Idle };
        }
        else {
            return __assign(__assign({}, oldsState), newState);
        }
    };
    var _b = useState(initialUrl), url = _b[0], setUrl = _b[1];
    var _c = useState(initialBody), postBody = _c[0], setPostBody = _c[1];
    var _d = useReducer(reducer, {
        status: LoaderStatus.Idle,
    }), response = _d[0], setResponse = _d[1];
    useEffect(function () {
        var abortController = new AbortController();
        var signal = abortController.signal;
        if (url && (postBody || !waitForBody)) {
            fetchData(setResponse, url, signal, getAuthHeader, getAccessTokenSilently, postBody !== null && postBody !== void 0 ? postBody : initialBody, headers);
        }
        return function () {
            if (!signal.aborted) {
                abortController.abort(); // Abort only if the signal hasn't already been aborted
            }
        };
    }, [url, postBody]);
    var refresh = function () {
        var abortController = new AbortController();
        var signal = abortController.signal;
        if (url && (postBody || !waitForBody)) {
            fetchData(setResponse, url, signal, getAuthHeader, getAccessTokenSilently, postBody !== null && postBody !== void 0 ? postBody : initialBody, headers);
        }
    };
    if (!initialUrl && !url) {
        return [{ status: LoaderStatus.Idle }, setUrl, setPostBody, refresh];
    }
    return [response, setUrl, setPostBody, refresh];
};
export default useFetch;
