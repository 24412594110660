var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { routeNames } from "../../../../util/routes";
import { ComplianceIcon, StrippedLink, Table, TD, TH, TR, } from "../../../common-styled-components";
import { complianceOptions, getCompliance, } from "../../../../util/compliance/compliance";
import { theme } from "../../../../theme";
import { sortBy, values } from "lodash";
import { formatNumber } from "../../../../util/amountUtils";
import { formatDate } from "../../../../util/timeUtils";
var voyageTableColumns = function () { return [
    {
        key: "index",
        label: "Report",
    },
    {
        key: "start_date",
        label: "Date started",
    },
    {
        key: "end_date",
        label: "Date ended",
    },
    {
        key: "origin_name",
        label: "Origin",
    },
    {
        key: "destination_name",
        label: "Destination",
    },
    {
        key: "distance",
        label: "Distance (NM)",
    },
    {
        key: "type_id",
        label: "Type",
    },
    {
        key: "intensity",
        label: "Intensity (gCO₂e/MJ)",
    },
    {
        key: "balance",
        label: "+/- (tCO₂e)",
    },
    {
        key: "fuels",
        label: "Fuels",
    },
]; };
var CellContent = function (_a) {
    var index = _a.index, voyage = _a.voyage, contentKey = _a.contentKey, metadata = _a.metadata, isInAdminTools = _a.isInAdminTools;
    var compliance = getCompliance(metadata, 2025, voyage.intensity.intensity_gCO2e_per_MJ);
    switch (contentKey) {
        case "index":
            var link = isInAdminTools
                ? "".concat(routeNames.admin, "/").concat(routeNames.voyageAdmin, "/").concat(voyage.id)
                : "".concat(routeNames.voyage, "/").concat(voyage.id);
            return (_jsx(StrippedLink, __assign({ to: link, style: { color: theme.colors.blue } }, { children: index })));
        case "start_date":
            return _jsx("div", { children: formatDate(new Date(voyage.start_date), true) });
        case "end_date":
            return _jsx("div", { children: formatDate(new Date(voyage.end_date), true) });
        case "type_id":
            return _jsx("div", { children: voyage.type_id === 1 ? "Voyage" : "Port stay" });
        case "fuels":
            return (_jsx("div", { children: values(voyage.fuels)
                    .map(function (fuel) {
                    return "".concat(formatNumber(fuel.amount), "t ").concat(metadata.fuels[fuel.fuel].name);
                })
                    .join(", ") }));
        case "intensity":
            return (_jsxs("div", __assign({ style: { color: complianceOptions[compliance].color } }, { children: [_jsx(ComplianceIcon, { src: complianceOptions[compliance].icon }), formatNumber(voyage.intensity.intensity_gCO2e_per_MJ, 2)] })));
        case "balance":
            return (_jsxs("div", __assign({ style: { color: complianceOptions[compliance].color } }, { children: [voyage.intensity.emission_balance_ton > 0 ? "+" : "", formatNumber(voyage.intensity.emission_balance_ton, 2)] })));
        case "origin_name":
            return (_jsx("div", { children: voyage.type_id === 1 ? voyage.origin_name : voyage.destination_name }));
        case "destination_name":
            return (_jsx("div", { children: voyage.type_id === 1 ? voyage.destination_name : _jsx("div", {}) }));
        default:
            return _jsx("div", { children: voyage[contentKey] });
    }
};
export var VoyageTable = function (_a) {
    var voyages = _a.voyages, complianceYear = _a.complianceYear, metadata = _a.metadata, isInAdminTools = _a.isInAdminTools;
    return (metadata && (_jsxs(Table, { children: [_jsx("thead", { children: _jsx("tr", { children: voyageTableColumns().map(function (c) { return (_jsx(TH, { children: c.label }, c.key)); }) }) }), _jsx("tbody", { children: sortBy(voyages, function (v) { return new Date(v.end_date); }).map(function (voyage, index) {
                    return (_jsx(TR, { children: voyageTableColumns().map(function (c) { return (_jsx(TD, { children: _jsx(CellContent, { index: index + 1, contentKey: c.key, voyage: voyage, isInAdminTools: isInAdminTools, metadata: metadata }) }, c.key)); }) }, voyage.id));
                }) })] })));
};
